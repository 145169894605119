import { moduleLogger } from "./util";
import { type Action, type Feature, type FeatureStatus, submitEvent, submitMerge, submitMove } from "@otto-ec/tracking-bct";

const log = moduleLogger.scope("tracking");

export function buildTrackingPayloadCinema(status: FeatureStatus, cinemaDom: HTMLElement, featureOrder: number): Feature | undefined {
	const cinemaTrackingData = cinemaDom.dataset.cinemaTracking;
	if (cinemaTrackingData) {
		const labels: Record<string, string[]> = JSON.parse(cinemaTrackingData) as Record<string, string[]>;
		log.info("cinema tracking:", cinemaTrackingData);
		return {
			id: "FT9-benefit-main",
			name: "BenefitCinema",
			status,
			position: featureOrder,
			labels,
		};
	}
}

/*        */
export class TrackingDataset {
	constructor(
		readonly id: string,
		readonly name: string,
		readonly labels: Record<string, string[]>,
	) {}
}

export function buildTrackingPayloadBenefit(
	status: FeatureStatus,
	benefitDom: HTMLElement,
	parentId: string,
):
	| (Feature &
			NonNullable<{
				labels: Record<string, string[]>;
			}>)
	| undefined {
	const benefitPosition = benefitDom.getAttribute("data-benefit-position") ?? "0"; /*                                                                 */

	const benefitTrackingData = benefitDom.dataset.tracking;
	if (benefitTrackingData) {
		const tracking: TrackingDataset = JSON.parse(benefitTrackingData) as TrackingDataset;
		log.info("benefit tracking:", benefitTrackingData);
		return {
			id: `${tracking.id}${benefitPosition}`,
			name: tracking.name,
			status,
			position: Number.parseInt(benefitPosition),
			labels: tracking.labels,
			parentId,
		};
	}
}

export class TrackingService {
	sendEvent(payload: Action) {
		log.info(`Send 'event'`, payload);
		submitEvent({}, payload);
	}

	sendMove(payload: Action) {
		log.info(`Send 'move event'`, payload);
		submitMove({}, payload);
	}

	sendMergeForCinema(payload: Feature[]) {
		log.info(`Send 'merge for cinema'`, payload);
		submitMerge({}, payload);
	}
}
